<template>
  <AppPageCard>
    <template #card-title> Employee Attendance Monthly Pdf Report</template>
    <CommonOrganisationSummary
      :item="transformItemCompany(company)"
    ></CommonOrganisationSummary>
    <AttendancePdfTable
      :items="list"
      :pagination="meta"
      :is-busy="isBusy"
    ></AttendancePdfTable>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import { mapGetters } from "vuex";
import AttendancePdfTable from "@/modules/company/components/shared/pdf/AttendancePdfTable";
import CommonOrganisationSummary from "@/modules/company/components/shared/company/CommonOrganisationSummary";

export default {
  name: "EmployeeMonthlyAttendancePdfPage",
  components: { CommonOrganisationSummary, AttendancePdfTable, AppPageCard },
  data() {
    return {
      isBusy: false,
      isPrintable: false,
    };
  },

  mounted() {
    this.timer();
  },

  methods: {
    timer() {
      setTimeout(function () {
        this.print();
      }, 100);
    },

    print() {
      window.print();
    },

    transformItemCompany(data) {
      return {
        name: data.org_name,
        code: data.org_no,
        email: data.org_email,
        phone: data.org_phone,
      };
    },
  },
  watch: {
    isPrintable: function (val) {
      console.log("Printable: ", val);
      if (val) {
        this.print();
      }
    },
  },
  computed: {
    ...mapGetters({
      list: "getEmployeeMonthlyAttendanceList",
      meta: "getEmployeeMonthlyAttendanceMeta",
      company: "getCompany",
    }),
  },
};
</script>

<style scoped></style>
